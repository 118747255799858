import { ReactComponent as DashboardIcon } from "@src/assets/images/Icons/DashboardIcon.svg";
import { ReactComponent as AcademyIcon } from "@src/assets/images/Icons/AcademyIcon.svg";
import { ReactComponent as CoachIcon } from "@src/assets/images/Icons/CoachIcon.svg";
import { ReactComponent as TournamentIcon } from "@src/assets/images/Icons/TournamentIcon.svg";
import { ReactComponent as TransactionsIcon } from "@src/assets/images/Icons/TransactionsIcon.svg";
import { ReactComponent as UserIcon } from "@src/assets/images/Icons/UserIcon.svg";

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <DashboardIcon size={30} />,
    navLink: "/dashboard",
  },
  {
    id: "user",
    title: "Users",
    icon: <UserIcon size={30} />,
    navLink: "/users",
  },
  {
    id: "coach",
    title: "Coach",
    icon: <CoachIcon size={30} />,
    navLink: "/coach",
  },
  {
    id: "academy",
    title: "Academy",
    icon: <AcademyIcon size={30} />,
    navLink: "/academy",
  },
  {
    id: "tournament",
    title: "Tournament",
    icon: <TournamentIcon size={30} />,
    navLink: "/tournament",
  },
  {
    id: "transactions",
    title: "Transactions",
    icon: <TransactionsIcon size={30} />,
    navLink: "/transaction",
  },
];

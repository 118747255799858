export const API_ENDPOINTS = {
  ADMIN_LOGIN: "/admin/login",
  ADMIN_DETAIL: "/admin/getMe",
  GET_DASHBOARD_OVERVIEW: "/dashboard/overview",
  GET_USERS_LIST: "/user/list",
  GET_COACH_LIST: "/user/list",
  GET_ACADEMY_LIST: "/academy/all",
  GET_TOURNAMENT_LIST: "/tournament/all",
  GET_TRANSACTIONS_LIST: "/transaction/all",
};
